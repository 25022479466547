module.exports = [{
      plugin: require('../node_modules/@taboola/venus-core/plugins/plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/venus_builder/venus-ci/workspace/tib/master/node_modules/@taboola/venus-core/src/layouts/page-layout.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[null,null,null,[null,{"strict":"ignore"}],null,null],"rehypePlugins":[[null,{"strict":"ignore"}]],"gatsbyRemarkPlugins":[{"resolve":"/home/venus_builder/venus-ci/workspace/tib/master/node_modules/gatsby-remark-images","id":"4178bf40-6c96-5183-b40e-db3e531cda02","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/home/venus_builder/venus-ci/workspace/tib/master/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1250,"linkImagesToOriginal":false,"withWebp":{"quality":80},"quality":80},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/venus_builder/venus-ci/workspace/tib/master/node_modules/gatsby-remark-gifs","id":"57a928a3-d51c-51d6-8e1e-94d8b84fccdf","name":"gatsby-remark-gifs","version":"1.2.0","modulePath":"/home/venus_builder/venus-ci/workspace/tib/master/node_modules/gatsby-remark-gifs/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/venus_builder/venus-ci/workspace/tib/master/node_modules/gatsby-remark-autolink-headers","id":"a707f948-8079-56bc-86b2-af789db934c3","name":"gatsby-remark-autolink-headers","version":"5.25.0","modulePath":"/home/venus_builder/venus-ci/workspace/tib/master/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"icon":false,"isIconAfterHeader":false,"elements":["h2"]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]}],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/venus_builder/venus-ci/workspace/tib/master","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1250,"linkImagesToOriginal":false,"withWebp":{"quality":80},"quality":80},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"isIconAfterHeader":false,"elements":["h2"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/TIB-logo-small.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e61e76d12eb69c594121960a5ca345b8"},
    },{
      plugin: require('../node_modules/@taboola/venus-core/plugins/single-article/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@taboola/venus-core/gatsby-browser.js'),
      options: {"plugins":[],"siteMetadata":{"site":"TIB","siteUrl":"https://theinsurancebulletin.com","image":"/TIB-logo.svg","iconPath":"static/TIB-logo-small.svg","title":"The Insurance Bulletin","titleTemplate":"%s · The Insurance Bulletin","description":"The Insurance Bulletin is a content publication website that aims to empower you to make the right choice when it comes to insurance.","twitter":{"link":"https://x.com/TIB_site/","handle":"@TIB_site"},"facebook":{"link":"https://facebook.com/The-Insurance-Bulletin-100929388814026/","adsAccountId":553370069783226},"taboola":{"pixel":1398723,"feed":"venus-theinsurancebulletin-publisher"},"google":{"tagManager":"GTM-5FR9X4Q"},"impact":{"verificationToken":1624832810}}},
    },{
      plugin: require('../node_modules/@taboola/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"enableIdentityWidget":false,"htmlTitle":"CMS - The Insurance Bulletin","htmlFavicon":"static/TIB-logo-small.svg","modulePath":"/home/venus_builder/venus-ci/workspace/tib/master/node_modules/@taboola/venus-core/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
